@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  margin: 0;
  font-weight: 400;
  color: #444;
  text-decoration: none;
}

input {
  outline: none;
  border: 0;
}

/* ANIMATION */
.animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  animation-name: flash;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}
