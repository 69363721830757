@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  margin: 0;
  font-weight: 400;
  color: #444;
  text-decoration: none;
}

input {
  outline: none;
  border: 0;
}

/* ANIMATION */
.animated {
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  -webkit-animation-name: flash;
          animation-name: flash;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}

